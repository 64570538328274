import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Redirect} from 'react-router-dom';
import { updateNationalIdentity } from '../services/user';

const VerifyEmail = (props) => {
    const [error, setError] = useState(null);
    const [nationalIdentity, setNationalIdentity] = useState('');

    useEffect(() => {
        document.title = '47 Street - Activá tu cuenta';
    }, []);

    const Modify = async (e) => {
        e.preventDefault();

        const cleaned = nationalIdentity.replace(/[^0-9]/gi, '');

        if (cleaned === '' || cleaned.length > 9 || cleaned.length < 7) {
            return setError('Por favor, ingresá un DNI válido.');
        }

        try {
            const req = await updateNationalIdentity(cleaned);

            if (req.found) {
                return setError('El DNI ingresado ya se encuentra registrado.');
            }

            props.userData(req.user);
        } catch (err) {
            return setError('No podemos validar tu DNI en este momento.');
        }
    };

    return (
        <>
        {
            props.user.national_identity.indexOf('RANDOM_') === -1
            ?
            <Redirect to="/" />
            :
            <>
                <div className="bg-black signup-header text-center">
                    <div className="container">
                        <img src="../assets/svg/brand/logo-white.svg" alt={process.env.REACT_APP_BRAND} className="brand-icon-lg mb-4" />
                    </div>
                </div>
                <div className="container">
                    <div className="simple-login-form rounded-12 shadow-dark-80 bg-white mb-0">
                        <h2 className="mb-3">Ingresá tu DNI</h2>
                        <form className="pt-md-1" onSubmit={Modify}>
                            <p className="text-gray-700 pb-md-4">Completá tu DNI para activar tu cuenta y empezar a sumar puntos!</p>
                            <div className="mb-4 pb-md-2">
                                <label className="form-label form-label-lg" htmlFor="national_identity">Número de Documento</label>
                                <input type="text" className={`form-control form-control-xl ${error ? 'is-invalid' : ''}`} id="national_identity" placeholder="Nro. de Documento" value={nationalIdentity} onChange={(e) => setNationalIdentity(e.target.value)} />
                            </div>
                            <div className="d-grid pb-1">
                                <button type="submit" className="btn btn-xl btn-primary">Continuar</button>
                            </div>
                            {error && <p className="fw-bold text-danger mb-5">{error}</p>}
                        </form>
                    </div>
                    <div className="text-center py-4">
                        <span className="text-gray-600 small">
                            Si tenés inconvenientes para ingresar tu DNI <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className="text-gray-600"><u>contactanos</u></a>
                        </span>
                    </div>
                </div>
            </>
            }
        </>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
    valid: () => dispatch({ type: 'EMAIL_VALID' }),
    userData: (user) => dispatch({ type: 'USER_DATA', user }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
