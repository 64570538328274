import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Logo from '../assets/svg/brand/logo.svg';
import LogoWhite from '../assets/svg/brand/logo-white.svg';
import NotFound404 from '../assets/img/placeholders/404-error.jpg';

const NotFound = () => {
    useEffect(() => {
        document.title = '47 Street - Página no encontrada';
    }, []);
    
    return (
        <>
        <div className="position-absolute top-0 start-0 p-4 p-md-5">
            <span className="ps-md-3">
                <img src={Logo} alt="47 Street" className="dark-logo" />
                <img src={LogoWhite} alt="47 Street" className="white-logo" />
            </span>
        </div>
        <div className="row g-0 align-items-center">
            <div className="col-lg-7">
                <div className="bg-white cover-fit d-flex justify-content-center align-items-center px-4 py-5 p-sm-5">
                    <img src={NotFound404} alt="Sign Up Cover" className="mt-4 mt-md-0 dark-mode-image" />
                </div>
            </div>
            <div className="col-lg-5 px-md-3 px-xl-5">
                <div className="px-3 py-4 p-md-5 p-xxl-5 mx-xxl-4">
                    <div className="login-form py-2 py-md-0 mx-auto mx-lg-0">
                        <h2 className="h1 mb-3 pb-md-1">Página no disponible</h2>
                        <p className="text-gray-700 pb-md-4 mb-4">Lo sentimos, el contenido que buscás no está disponible.</p>
                        <div className="d-grid pb-3">
                            <NavLink to="/" className="btn btn-primary">Volver al Inicio</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

export default NotFound;
