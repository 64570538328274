import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Loader = (props) => {
    return (
        <TransitionGroup classNames="example">
            {
                props.loading
                &&
                <CSSTransition
                    classNames="example"
                    timeout={{ exit: 100, enter: 300 }}>
                    <div className="loader">
                        <div className="typing_loader"></div>
                    </div>
                </CSSTransition>
            }
        </TransitionGroup>
    );
};

const mapStateToProps = (state) => ({
    loading: state.loading.loading,
})

export default connect(mapStateToProps)(Loader);