import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuItem = (props) => {

    const id = `sidebar_${props.name.replace(' ', '')}`;

    return (
        <li className="nav-item">
            {props.screen ? (
                <NavLink className="nav-link collapsed" activeClassName="active" to={props.screen} exact={props.exact}>
                    { props.pill && <span className="notification-badge">{props.pill}</span> }
                    {props.Icon && <props.Icon className="svg-icon-menu" />} &nbsp;<span className="ms-2">{props.name}</span>
                </NavLink>
            ) : (
                <a className="nav-link collapsed" href={`#${id}`} data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls={id}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g data-name="icons/tabler/chart" transform="translate(0)">
                            <rect data-name="Icons/Tabler/Chart background" width="16" height="16"fill="none"></rect>
                            <path d="M.686,13.257a.686.686,0,0,1-.093-1.365l.093-.006H15.314a.686.686,0,0,1,.093,1.365l-.093.006ZM.394,9.535l-.089-.05a.688.688,0,0,1-.24-.863l.05-.088L3.773,3.048a.684.684,0,0,1,.782-.272l.095.039L7.811,4.4,11.121.257a.687.687,0,0,1,.945-.122L12.142.2,15.8,3.858a.686.686,0,0,1-.893,1.036l-.077-.067L11.713,1.712,8.536,5.685a.684.684,0,0,1-.743.225l-.1-.04L4.578,4.313,1.256,9.294a.684.684,0,0,1-.862.24Z"
                                transform="translate(0 1)" fill="#1e1e1e"></path>
                        </g>
                    </svg> &nbsp;<span className="ms-2">{props.name}</span>
                    { props.pill && <span className="notification-badge">{props.pill}</span> }
                </a>
            )}
            {
                props.sections &&
                <div className="collapse collapse-box" id={id} data-bs-parent="#accordionExample3">
                    <ul className="nav nav-sm flex-column">
                        { props.sections.map((section, i) => (
                            <li className="nav-item" key={i}>
                                <NavLink to={section.screen} className="nav-link" exact={section.exact}>{section.name}</NavLink>
                            </li>)
                        ) }
                    </ul>
                </div>
            }
        </li>
    );
};

export default MenuItem;