import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert';
import validator from 'validator';
import { requestPasswordReset } from '../services/user';

import LogoWhite from '../assets/svg/brand/logo-white.svg'

const ResetPassword = (props) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState();

    useEffect(() => {
        document.title = '47 Street - Modificá tu Contraseña';
    }, []);

    const request = async (e) => {
        e.preventDefault();
        setError(null);

        if (!email || (email && !validator.isEmail(email))) {
            return setError('Por favor, ingresá un email válido.');
        }

        const status = await requestPasswordReset(email);
        
        if (status === 'Too many requests, please try again later.') {
            setEmail('')
            setError('Ya enviamos el link. Por favor, revisa tu carpeta de SPAM o esperá unos minutos antes de solicitar un nuevo link.')
        }

        if (status.success) {
            setEmail('');
            return swal({   
                title: "Listo!",   
                text: `Enviamos el link de recuperación a ${email}.`,
                icon: "success",
            }).then(() => {
                props.history.push('/ingresa');
            });
        }
    };

    return (
        <>
            <div className="bg-black signup-header text-center">
                <div className="container">
                    <NavLink to="/ingresa">
                        <img src={LogoWhite} alt={process.env.REACT_APP_BRAND} className="brand-icon-lg mb-4" />
                    </NavLink>
                </div>
            </div>
            <div className="container">
                <div className="simple-login-form rounded-12 shadow-dark-80 bg-white mb-0">
                    <h3 className="mb-3">Recuperá tu contraseña</h3>
                    <form className="pt-0 pt-md-2" onSubmit={request}>
                        <div className="mb-4 pb-md-2">
                            <label className="form-label form-label-lg" htmlFor="EmailAddress">Email</label>
                            <input type="email" className="form-control form-control-xl" id="EmailAddress" placeholder="Tu email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="d-grid mb-4 pb-md-2">
                            <button type="submit" className="btn btn-xl btn-primary">Recuperar</button>
                        </div>
                        {error && <p className="text-danger fw-bold">{error}</p>}
                    </form>
                </div>
                <div className="text-center py-4">
                    <span className="text-gray-600 small">
                        Si tenés inconvenientes para recuperar tu contraseña <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className="text-gray-600"><u>contactanos</u></a>
                    </span>
                </div>
            </div>
        </>
    )
};

export default ResetPassword;
