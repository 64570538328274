import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { login } from '../services/user';
import LogoWhite from '../assets/svg/brand/logo-white.svg';

const Login = (props) => {
    const [auth, setAuth] = useState(props.auth);
    const [error, setError] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [referrer, setReferrer] = useState(null);
    const path = props.location.search.substr(6);

    useEffect(() => {
        document.title = '47 Street - Ingresá a tu cuenta';
    }, []);

    useEffect(() => {
        const start = () => {
            // Get referrer
            if (!props.location.search || props.location.search === '') {
                return false;
            }

            const params = props.location.search.replaceAll('?', '&').split('&');
            params.forEach(item => {
                const a = item.split('=');
                if (a[0] === 'ref') {
                    setReferrer(a[1]);
                }
            });
        }
        
        start();
    }, [props.location.search]);

    useEffect(() => {
        if (auth) {
            if (path) {
                props.history.push(path);
            } else {
                props.history.push('/');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])

    const submit = async (event) => {
        event.preventDefault();
        if (!email || !password) {
            return errorHandler(0);
        }

        try {
            const loginResult = await login(email, password);
            if (!loginResult.success) {
                errorHandler(loginResult.error);
            }
            if (loginResult.token) {
                setAuth(true);
            }
        } catch (e) {
            return errorHandler(e.status);
        }
    };

    const errorHandler = (status) => {
        let text = '';
        switch (status) {
            case 0:
                text = 'Por favor, completá tus datos';
                break;
            case 'Authentication error':
                text = 'Por favor, revisa las credenciales ingresadas.';
                break;
            case 'User not found':
                text = 'Por favor, revisa las credenciales ingresadas.';
                break;
            case 500:
                text = 'Servicio no disponible.';
                break;
            default:
                text = 'Hubo un problema. Inténtalo más tarde.'
                break;
        }
        return setError(text);
    };

    const handleInputChanges = (e) => {
        switch (e.target.id) {
            case 'email':
                setEmail(e.target.value);
                break;
            case 'password':
                setPassword(e.target.value);
                break;
            default:
                break;
        }
    };

    return (
        <div className="signup-template">
            <div className="position-absolute top-0 start-0 p-4 p-md-5 w-100">
                <span className="ps-lg-3">
                    <img src={LogoWhite} alt="47 Street" className="brand-icon-lg" />
                </span>
            </div>
            <div className="row g-0 align-items-center">
                <div className="col-lg-7 bg-black">
                    {
                        props.assets.signin_cover_web && <img src={props.assets.signin_cover_web} className="cover-fit" alt="Sign in Cover" />
                    }
                </div>
                <div className="col-lg-5 px-md-3 px-xl-5">
                    <div className="px-3 py-4 p-md-5 p-xxl-5 mx-xxl-4">
                        <div className="login-form py-2 py-md-0 mx-auto mx-lg-0">
                            <h2 className="h1 mb-3">Ingresá</h2>
                            <form className="pt-2" onSubmit={submit}>
                                <div className="mb-4 pb-md-2">
                                    <label className="form-label form-label-lg" htmlFor="email">Email</label>
                                    <input type="email" className="form-control form-control-xl" id="email" placeholder="Email" value={email} onChange={handleInputChanges} />
                                </div>
                                <div className="mb-4 pb-md-2">
                                    <label className="form-label form-label-lg" htmlFor="Password">Contraseña</label>
                                    <input type="password" className="form-control form-control-xl" id="password" placeholder="Contraseña" value={password} onChange={handleInputChanges} />
                                </div>
                                <div className="d-grid">
                                    <p className="text-danger fw-bold">{error}</p>
                                    <button type="submit" className="btn btn-xl btn-primary">Ingresar</button>
                                </div>
                                <div className="my-4 d-flex pb-3">
                                    <NavLink to="/recuperar-contrasena" className="small text-gray-600 ms-auto mt-1">¿Olvidaste tu contraseña?</NavLink>
                                </div>
                                <div className="border-top border-gray-200 pt-4">
                                    <span className="text-gray-700">¿No tenés cuenta? <NavLink to={`/registrate${referrer ? `?ref=${referrer}` : ''}`} className="link-primary">Registrate</NavLink></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    auth: state.auth.isAuthenticated,
    assets: state.assets,
});

export default connect(mapStateToProps)(Login);