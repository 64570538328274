import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Redirect} from 'react-router-dom';
import swal from 'sweetalert';
import validator from 'validator';
import { checkEmailValidation, requestEmailValidation, verifyEmail } from '../services/user';
import Logo from '../assets/svg/brand/logo-white.svg';

const VerifyEmail = (props) => {
    const [seconds, setSeconds] = useState(60);
    
    useEffect(() => {
        document.title = '47 Street - Verificá tu Email';

        const i = setInterval(() => {
            setSeconds(s => s > 0 ? s - 1 : s);
        }, 1000);

        return () => {
            clearInterval(i);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const check = async () => {
        const status = await checkEmailValidation();

        if (status.validated) {
            props.valid();
        } else {
            return swal({   
                title: "Ups.",   
                text: 'Parece que aún no validaste tu email. Por favor, seguí las instrucciones en el email que te enviamos.',   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const request = async (email) => {
        if (email && !validator.isEmail(email)) {
            return swal({   
                title: "Ups.",   
                text: 'Por favor, ingresá un email válido.',   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }

        const emailValid = await verifyEmail(email);

        if (!emailValid) {
            return swal({   
                title: "Ups.",   
                text: 'No podemos validar tu email en este momento.',   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
        
        if (!emailValid.data.isValid) {
            if (emailValid.data.type === 'typo' && emailValid.data.correctEmail) {
                return swal({   
                    title: "Ups.",   
                    text: `Hay un error en tu email. Quisiste decir ${emailValid.data.correctEmail}?`,   
                    icon: "error",
                    confirmButtonColor: "#DD6B55",
                    className: 'logout-alert'
                });
            }
            return swal({   
                title: "Ups.",   
                text: 'Por favor, ingresá un email válido.',   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }

        const status = await requestEmailValidation(email);

        if (status.success) {
            setSeconds(60);
            props.userData(status.user);
            return swal({   
                title: "Listo!",   
                text: email ? `Enviamos el email de validación a ${email}.` : `Ya reenviamos el email. Por favor, revisa la carpeta de spam.`,   
                icon: "success",
            })
        } else {
            if (status.error === "User already exists for that email") {
                return swal({   
                    title: "Ups.",   
                    text: 'El email ingresado ya se encuentra registrado.',   
                    icon: "error",
                    confirmButtonColor: "#DD6B55",
                    className: 'logout-alert'
                });
            }
            return swal({   
                title: "Ups.",   
                text: 'No podemos enviar el email en este momento.',   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const fixEmail = () => {
        swal({
            title: "Modificá tu email",
            text: "Por favor, ingresá el nuevo email",
            content: 'input',
            buttons: true,
            dangerMode: false,
          })
          .then(async (email) => {
            if (email) {
                request(email);
            }
          });
    }

    return (
        <>
        {
            props.user.email_validation
            ?
            <Redirect to="/" />
            :
            <>
            <div className="bg-black signup-header text-center">
                <div className="container">
                    <img src={Logo} alt={process.env.REACT_APP_BRAND} className="brand-icon-lg mb-4" />
                </div>
            </div>
            <div className="container">
                <div className="simple-login-form rounded-12 shadow-dark-80 bg-white mb-0 text-center">
                    <h2 className="mb-3">Validá tu email</h2>
                    <form className="pt-md-1">
                        <p className="text-gray-700 pb-md-4">Enviamos un email de validación a<br /><span className="text-gray-700 font-weight-semibold">{props.user.email}</span> <br />Seguí las instrucciones del email para activar tu cuenta</p>
                        <div className="d-grid pb-1">
                            <button type="button" className="btn btn-xl btn-primary" onClick={check}>Ya validé mi email</button>
                        </div>
                        <div className="mt-3 mt-sm-4 border-top border-gray-200 pt-3">
                            {
                                seconds < 1 ?
                                <p className="text-gray-700 mb-2">No recibiste el email? <button type="button" onClick={() => request()} className="btn btn-link btn-lg link-primary">Reenvialo</button></p>
                                : <p className="text-gray-700 mb-2">Esperá {seconds} segundo{seconds === 1 ? '' : 's'} para reenviar el email.</p>
                            }
                            
                            <p className="text-gray-700 mb-0">El email no es correcto? <button type="button" className="btn btn-link btn-lg link-primary" onClick={fixEmail}>Modificalo</button></p>
                        </div>
                    </form>
                </div>
                <div className="text-center py-4">
                    <span className="text-gray-600 small">
                        Si tenés inconvenientes para validar tu email <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className="text-gray-600"><u>contactanos</u></a>
                    </span>
                </div>
            </div>
            </>
            }
        </>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
    valid: () => dispatch({ type: 'EMAIL_VALID' }),
    userData: (user) => dispatch({ type: 'USER_DATA', user }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
