import axios from "axios";

const CancelToken = axios.CancelToken;
export let cancel;


export const getList = async () => {
    try {
        const request = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/notifications`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                }),
            },
        );
        return request.data;
    } catch (err) {
        throw err;
    }
};

export const markAsRead = async (notifications = []) => {
    try {
        const request = await axios.post(`${process.env.REACT_APP_API_URL}/api/notifications/read`, {
            notifications,
        });
        return request.data;
    } catch (err) {
        throw err;
    }
};

export const markAsUnread = async (notifications = []) => {
    try {
        const request = await axios.delete(`${process.env.REACT_APP_API_URL}/api/notifications/read`, {
            notifications,
        });
        return request.data;
    } catch (err) {
        throw err;
    }
};
